<template>
  <div v-show="isVisible" class="message" :class="type">
    <div v-if="showTitle" class="message-header">
      <p>{{ messageTitle }}</p>
      <button
        class="delete"
        aria-label="Schliessen"
        @click="closeMessage"
      />
    </div>
    <div class="message-body">
      <slot />
      <div v-if="text" v-html="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  autoHide: {
    type: Boolean,
    default: false,
  },
})

const isVisible = ref(true)
const { $texts } = useNuxtApp()

const messageTitle = computed(() => {
  let type = props.type
  if (props.title) {
    type = ''
  }
  switch (type) {
    case 'is-success':
      return $texts('success', 'Erfolgreich!')
    case 'is-danger':
      return $texts('error', 'Fehler')
    default:
      return props.title
  }
})

const closeMessage = () => {
  nextTick(() => {
    isVisible.value = false
  })
}

onMounted(() => {
  if (props.autoHide) {
    setTimeout(closeMessage, 1000)
  }
})
</script>
